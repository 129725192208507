import React, { useEffect, useState, useContext } from 'react';

import under_construction from "./resources/under_construction.jpg";
import "./styles/main.css";

function App() {
  return (
    <div className='main-div-img' style={{background: `url(${under_construction})`}}>
      <label className='main-lbl'>VENTA GDL EST&Aacute; EN CONSTRUCCI&Oacute;N</label>
    </div>
  );
}

export default App;
