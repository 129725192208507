import React, { createContext, useState, useEffect } from "react";

import { categoriesServices } from "./functions/categories.services";
import { usersServices }      from "./functions/users.services";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [user,       setUser]       = useState(null);
    const [categories, setCategories] = useState([]);
    const [loading,    setLoading]    = useState(false);

    const getAllCategories = async () => {
        let _allCate = await categoriesServices.getAllCategories();
        if(_allCate.error === false){
            setCategories(_allCate.data)
        }
    };

    const getUserData = async () => {
        let _myUser = await usersServices.getMyUser();
        if(_myUser.error === false && _myUser.data !== undefined){
            setUser(_myUser.data);
        }
    }

    const goTo = (link) => {
        window.location.href = link;
    };

    useEffect(() => {
        if(user === null){
            let _t = localStorage.getItem("_vg");
            if(_t !== undefined && _t !== null){
                _t = JSON.parse(_t);
                if(_t.token !== undefined && _t.token !== null){
                    getUserData();
                }
            }
        }
    }, []);

    const value = {
        /// Variables
        user, categories, loading, setLoading,
        /// Funcions
        getAllCategories, goTo
    };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;