import { API_URL } from "./api.url.back";

export const usersServices = {
    login,
    getMyUser,
    createAccount,
    setMyStore
};

function handleResponseAlternative(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        const responseData = {
        error: false,
        data: data,
        };
        if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            //logout();
        }

        responseData.error = true;
        return responseData;
        }
        return responseData;
    });
}

function getToken() {
    let _t = localStorage.getItem("_vg");
    if(_t !== undefined && _t !== null){
        _t = JSON.parse(_t);
        return _t.token;
    }
    return null;
}

function login(user, pass) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            username : user,
            password : pass
        })
    };

    return fetch(`${API_URL}/users/login`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function getMyUser() {
    let _t = getToken();
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `${_t}` },
    };

    return fetch(`${API_URL}/users`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function createAccount(user, email, pass, phone) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            name     : user,
            email    : email,
            password : pass,
            phone    : phone
        })
    };

    return fetch(`${API_URL}/users`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function setMyStore(storeId) {
    let _t = getToken();
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `${_t}` },
        body: JSON.stringify({
            id_store : storeId
        })
    };

    return fetch(`${API_URL}/users/setstore`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

/*
function getAllPriorities(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/alert/prioritys`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllHospitals(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/hospital`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllDepartments(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/departments`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllHierarchies(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/hierarchies`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllShifts(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/shifts`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllAreas(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/areas`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getAllGroups(token) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/groups`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function createUser(username, password, first_name, last_name, full_name, alias, email, phone, rol, change_password, token) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify({
            username : username,
            password: password,
            first_name: first_name,
            last_name: last_name,
            full_name: full_name,
            alias: alias,
            email: email,
            phone: phone,
            system_rol: rol,
            change_password: change_password
        }),
    };
  
    return fetch(`${API_URL}/user`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function activateUser(id, token) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/user/active/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function deactivateUser(id, token) {
    const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };
  
    return fetch(`${API_URL}/user/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}


function createAlert(body, token) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: body,
    };
  
    return fetch(`${API_URL}/alert`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getUserAlerts(id, token){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/notifications/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((user) => {
        return user;
    });
}

function getUserAlertsCreated(token){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/alert`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function getUserAlertDetails(id, token){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/notifications/details/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function userAlertReact(idAlert, reacted, token){
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/notifications/react/${idAlert}/${reacted}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function userAlertResponse(idAlert, response, token){
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify({
            user_response: response
        })
    };

    return fetch(`${API_URL}/notifications/response/${idAlert}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function deleteAlert(id, token){
    const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/alert/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function uploadFile(id, body, token){
    const requestOptions = {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}` },
        body: body,
    };
  
    return fetch(`${API_URL}/files/${id}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}

function getUserPending(page, token){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    };

    return fetch(`${API_URL}/notifications/pending/${page}`, requestOptions)
        .then(handleResponseAlternative)
        .then((data) => {
        return data;
    });
}
*/