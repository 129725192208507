import React, { useEffect, useState, useContext } from 'react';
import "../styles/loading.css";

import loading_gif from "../resources/icons/loading.gif";

import { AppContext } from '../context';

export default function Loading(props){

    let { loading } = useContext(AppContext);

    const [loadingRender, setLoadingRender] = useState(null);

    useEffect(() => {
        if(loading === true || props.loadingPage === true){
            setLoadingRender(
                <div className="loading-main-div">
                    <img src={loading_gif} className={(loading === true) ? "loading-img loading-alpha" : "loading-img" } />
                </div>
            );
        } else {
            setLoadingRender(null);
        }
    }, [loading])

    return(
        loadingRender
    );
};