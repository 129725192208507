import React, { lazy, Suspense } from 'react';
import './index.css';
import App from './App';
import { render } from 'react-dom';
import { NavLink, Router, Route, Switch } from 'react-router-dom';
import {createBrowserHistory} from "history";
import AppProvider from "./context";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './components/loading';

const Categories = lazy(() => import('./pages/categories'));
const Home       = lazy(() => import('./pages/home'));
const Login      = lazy(() => import('./pages/login'));
const User       = lazy(() => import('./pages/user'));
const Seller     = lazy(() => import('./pages/seller'));
const Signup     = lazy(() => import('./pages/signup'));

var hist = createBrowserHistory();

var sellersList = ["pk34", "creationpaws"];

render(
  <AppProvider>
    <Router history={hist}>
      <Suspense fallback={<Loading loadingPage={true} />}>
        <Switch>
          <Route path="/category/:categoryid?" component={Categories}></Route>
          <Route path="/home" component={Home}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/user" component={User}></Route>
          <Route path="/products/:categoryid?/:sellerid?" component={Categories}></Route>
          <Route path="/signup" component={Signup}></Route>
          {sellersList.map((s) => {
            return (<Route path={`/${s}`} component={Seller} key={`seller_name_${s}`}></Route>);
          })}
          <Route path="/" component={App}></Route>
        </Switch>
      </Suspense>
    </Router>
  </AppProvider>, 
  document.getElementById("root")
);